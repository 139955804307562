<template>
  <div class="container">
    <h2>Curriculum Vitae</h2>
    
    <h3>Education</h3>
        <p>Ph.D., Carnegie Mellon University, Pittsburgh, PA&nbsp;<br>
            Electrical and Computer Engineering, December 1990</p>
        <p>M.S., North Carolina State University, Raleigh, NC&nbsp;<br>
            Electrical and Computer Engineering, April 1986</p>
        <p>B.S., Birla Institute of Technology, Ranchi, India&nbsp;<br>
            Electrical Engineering, June 1984</p>
    
    <h3>Experience</h3>
        <p>Department of Computer Science, Columbia University, New York, NY&nbsp;<br>
        T. C. Chang Endowed Chair Professor, July 2002 - Present <br>
        Department Chair, July 2009 - June 2012 <br>
        Professor, December 1996 - June 2002 <br>
        Associate Professor, January 1995 - November 1996 <br>
        Assistant Professor, January 1991 - December 1994</p>
        <p>Snap Inc., New York, NY <br>
        Director, NYC Research, January 2018 - Present</p>
        <p>The Robotics Institute, Carnegie Mellon University, Pittsburgh, PA <br>
        Graduate Research Assistant, July 1986 - December 1990</p>
        <p>Hitachi, Ltd., Yokohama, Japan <br>
        Visiting Research Scientist, April 1989 - August 1989</p>
        <p>Taylor Instruments, New Delhi, India <br>
        Research Engineer, July 1984 - November 1984 </p>
    
    <h3>Honors</h3>
        <p>SPIE Luminary<br>
        The International Society of Optics and Photonics, Bellingham, Washington, 2021</p>
        <p>Funai Achievement Award<br>
        Information Processing Society of Japan, Sendai, 2021</p>
        <p>Distinguished Alumnus Award<br>
        Birla Institute of Technology, Mesra, 2021</p>
        <p>IEEE PAMI Distinguished Researcher Award&nbsp;<br>
        IEEE Computer Society, Seoul, South Korea, 2019</p>
        <p>Hall of Fame<br>
        North Carolina State University, Electrical and Computer Engineering, Raleigh, NC, 2016</p>
        <p>Distinguished Faculty Teaching Award&nbsp;<br>
        Columbia Engineering Alumni Association, New York, 2015</p>
        <p>Elected to the National Academy of Inventors&nbsp;<br>
        Tampa, FL, 2014</p>
        <p>Appreciation Honor for Contributions to Computational Imaging&nbsp;<br>
        Sony Corporation, Tokyo, 2014</p>
        <p>Elected to the American Academy of Arts and Sciences&nbsp;<br>
        Cambridge, MA, 2011</p>
        <p>Alumni Achievement Award&nbsp;<br>
        Carnegie Mellon University, Pittsburgh, PA, 2009</p>
        <p>Elected to the National Academy of Engineering&nbsp;<br>
        Washington, DC, 2008</p>
        <p>Great Teacher Award&nbsp;<br>
        Society of Columbia Graduates, New York, 2006</p>
        <p>T. C. Chang Endowed Professorship&nbsp;<br>
        Columbia University, New York, 2002</p>
        <p>Keck Engineering Teaching Excellence Award&nbsp;<br>
        W. M. Keck Foundation, Los Angeles, 1995</p>
        <p>NTT Distinguished Scientific Achievement Award&nbsp;<br>
        NTT Basic Research Laboratory, Japan, 1994</p>
        <p>Fellow of the Packard Foundation&nbsp;<br>
        David and Lucile Packard Foundation, Los Altos, 1992</p>
        <p>National Young Investigator Award&nbsp;<br>
        National Science Foundation, Washington, D.C., 1991</p>
    
    <h3>Best Paper Awards&nbsp;</h3>
        <p>Best Paper Award<br>
        ACM Multimedia Conference<br>
        Nice, France, 2019</p>
        <p>Helmholtz Prize&nbsp;<br>
        International Conference on Computer Vision&nbsp;<br>
        Seoul, South Korea, 2019</p>
        <p>Best Paper Award&nbsp;<br>
        User Interface Software and Technology&nbsp;<br>
        Quebec City, Canada, 2017</p>
        <p>Best Paper Award&nbsp;<br>
        IEEE International Conference on Computational Photography&nbsp;<br>
        Houston, TX, 2015</p>
        <p>Best Paper Award&nbsp;<br>
        IEEE International Conference on Computational Photography&nbsp;<br>
        Cambridge, MA, 2010</p>
        <p>Best Paper Award&nbsp;<br>
        IEEE Computer Vision and Pattern Recognition Conference&nbsp;<br>
        Washington, D.C., 2004</p>
        <p>Best Paper Honorable Mention Award&nbsp;<br>
        IEEE Computer Vision and Pattern Recognition Conference&nbsp;<br>
        Hilton Head, SC, 2000</p>
        <p>David Marr Prize&nbsp;<br>
        International Conference on Computer Vision&nbsp;<br>
        Boston, MA, 1995</p>
        <p>Siemens Outstanding Paper Award&nbsp;<br>
        IEEE Computer Vision and Pattern Recognition Conference&nbsp;<br>
        Seattle, Washington, 1994</p>
        <p>20th Pattern Recognition Award&nbsp;<br>
        Best Pattern Recognition Journal Paper Award&nbsp;<br>
        Jerusalem, Israel, 1994</p>
        <p>Best Industry Related Paper Award&nbsp;<br>
        International Conference on Pattern Recognition&nbsp;<br>
        Jerusalem, Israel, 1994</p>
        <p>David Marr Prize&nbsp;<br>
        International Conference on Computer Vision&nbsp;<br>
        Osaka, Japan, 1990</p>
      </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container{
    height: fit-content;
    width:1000px;
    margin-left:auto;
    margin-right: auto;
    display:block;
}
h2{
    padding-top:30px;
            font-family:'Raleway', sans-serif;
        font-size: 30px;
        width:100%;
        text-align: left;
        font-weight: 600;
}
h3{
            font-family:'Raleway', sans-serif;
        font-size: 23px;
        width:100%;
        text-align: left;
        font-weight: 600;
}

</style>